// -------------
// - Variables -
// -------------
:root {
  --animation-duration: 225ms;
  --animation-easing: cubic-bezier(.4, .0, .2, 1);
  --checkmark-color: var(--missive-green-color);
  --accent-color: var(--missive-blue-color);
  --button-color: var(--missive-blue-color);
  --button-hover-color: var(--button-color);
  --shadow-color: rgba(0,0,0, .2);
}

html[data-theme="dark"] {
  --shadow-color: rgba(0,0,0, .7);
}

// --------
// - Init -
// --------
html, body {
  margin: 0; padding: 0;
  width: 100%; height: 100%;
}

* {
  &, &:before, &:after {
    box-sizing: border-box;
  }
}

body, legend, input, textarea, button {
  color: var(--missive-text-color-a);
  font-size: var(--missive-font-size);
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', 'Apple Color Emoji', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--accent-color);
  text-decoration: none;
  &:hover { text-decoration: underline }
}

svg {
  fill: currentColor;
}

p {
  margin: 0.5em 0;
}

#component {
  width: 1px; min-width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

// -----------
// - Flexbox -
// -----------
.columns, .columns-vertical, .columns-middle,
.columns-left, .columns-center, .columns-right, .columns-justify {
  display: flex !important;
  & > * { flex-shrink: 0 }
}

.columns-vertical { flex-direction: column }
.columns-middle { align-items: center }
.columns-left { justify-content: flex-start }
.columns-center { justify-content: center }
.columns-right { justify-content: flex-end }
.columns-justify { justify-content: space-between }
.columns-wrap { flex-wrap: wrap }

.column-grow { flex-grow: 1 }
.column-shrink { flex-shrink: 1; min-width: 0 }

// --------------------
// - Margin / Padding -
// --------------------
$attrs: (margin padding);
$size-names: (xsmall small medium large xlarge);
$sides: (top right bottom left);
$sizes: (.25 .5 .75 1.2 1.4);

@each $attr in $attrs {
  .#{$attr} { #{$attr}: 1em }
  @each $side in $sides {
    .#{$attr}-#{$side} { #{$attr}-#{$side}: 1em }
  }

  @each $size-name in $size-names {
    $i: index($size-names, $size-name);
    $em: nth($sizes, $i);

    .#{$attr}-#{$size-name} { #{$attr}: #{$em}em }

    @each $side in $sides {
      .#{$attr}-#{$side}-#{$size-name} { #{$attr}-#{$side}: #{$em}em }
    }
  }
}

// ------------------
// - Text alignment -
// ------------------
$aligns: (left center right);

@each $align in $aligns {
  .align-#{$align} { text-align: #{$align} }
}

// --------------
// - Text color -
// --------------
$letters: (a b c d e);

@each $letter in $letters {
  .text-#{$letter} { color: var(--missive-text-color-#{$letter}) }
  .link-#{$letter} {
    user-select: none;
    cursor: pointer;
    color: var(--missive-text-color-#{$letter});
  }
}

// -------------
// - Text size -
// -------------
$size-names: (xsmall small normal large xlarge);
$increments: (-4 -2 0 3 6);

@each $size-name in $size-names {
  $i: index($size-names, $size-name);
  $increment: nth($increments, $i);

  .text-#{$size-name} { font-size: calc(var(--missive-font-size) + #{$increment}px) }
}

// ---------------
// - Text weight -
// ---------------
$weights: (400 500 600 700 800);

@each $weight in $weights {
  .text-#{$weight} { font-weight: $weight }
}

// ------------
// - Headings -
// ------------
.heading-c {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--missive-text-color-c);
}

// -----------
// - Helpers -
// -----------
.text-label {
  font-variant: all-petite-caps;

  @extend
    .text-c,
    .text-small;
}

.ellipsis {
  white-space: nowrap;
  max-width: 100%;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.section + .section {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid var(--missive-light-border-color);
}

.row + .row,
.row-small + .row { margin-top: .75em }

.row + .row-small,
.row-small + .row-small { margin-top: .25em }

.code, .hljs {
  font-size: 85%;
  font-family: Menlo, monospace;
  color: var(--accent-color);
  white-space: pre-wrap;
  padding: .2em .5em .1em;
  border-radius: 3px;
  background-color: var(--missive-background-color);

  a {
    text-decoration: underline;
    &:hover { text-decoration: none }
  }
}

.image-container {
  position: relative;

  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
  }
}

// ---------
// - Icons -
// ---------
.icon {
  position: relative;
  display: flex;
  font-style: normal;

  svg {
    position: absolute;
    top: 50%; left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.icon[data-badge] {
  position: relative;

  &:after {
    content: attr(data-badge);
    position: absolute;
    top: 50%; left: 100%;
    margin-left: 3px;
    color: var(--missive-text-color-c);
    transform: translateY(-50%);
  }
}

.loading-icon {
  svg {
    fill: none;
    stroke: currentColor;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 64;
  }

  .icon-circle {
    animation: rotate 1s infinite linear both;
    svg { animation: dasharray 1s infinite linear both }
  }
}

// --------
// - Form -
// --------
.checkmark,
.checkmark-large {
  appearance: none;
  cursor: pointer;
  width: 20px; height: 20px;
  border-radius: 50%;
  outline: 0;
  margin: 0;
  border: 1px solid var(--missive-light-border-color);

  &:hover {
    border-color: var(--checkmark-color);

    &:after {
      content: "";
      border-color: var(--checkmark-color);
    }
  }

  &:after {
    position: absolute;
    width: 6px; height: 10px;
    border: solid var(--missive-text-color-d);
    border-width: 0 1.5px 1.5px 0;
    transform: translateX(6px) translateY(3px) rotate(45deg);
  }

  &:checked, &.checkmark--checked {
    border-color: var(--checkmark-color);
    background-color: var(--checkmark-color);

    &:after {
      content: "";
      border-color: var(--missive-conversation-list-background-color);
    }
  }
}

.checkmark-large {
  border-width: 2px;

  &:after {
    transform: translateX(5px) translateY(2px) rotate(45deg);
  }
}

input[type="text"], textarea {
  padding: 8px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: var(--missive-border-radius);
  color: var(--text-color-a);
  border: 1px solid var(--missive-light-border-color);
  background-color: var(--missive-light-active-color);

  &:focus { box-shadow: 0 0 0 1px var(--missive-blue-color); }
}

.switch {
  position: relative;
  appearance: none;
  cursor: pointer;
  width: 42px; height: 21px;
  background-color: currentColor;
  border-radius: 10em;

  &:after {
    content: "";
    position: absolute;
    top: 1px; left: 1px;
    width: 19px; height: 19px;
    border-radius: 16px;
    transition: var(--animation-duration) var(--animation-easing);
    transition-property: width, left, transform;
    background-color: var(--missive-conversation-list-background-color);
  }

  &:active:after { width: 24px }
  &:checked:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
}

// -----------
// - Buttons -
// -----------
.button {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  padding: 8px 10px;
  color: #fff;
  font-weight: 500;
  border-radius: var(--missive-border-radius);
  background: var(--button-color);
  border-style: none;

  &:hover:not(:disabled) { background: var(--button-hover-color) }

  &:disabled {
    cursor: default;
    opacity: .5;
  }

  .button-icon-plus {
    font-size: 0;
    line-height: 0;

    i {
      font-size: 18px;
      font-style: normal;
    }

    & + span { margin-left: .3em }
  }

  &.button-small {
    font-size: calc(var(--missive-font-size) - 2px);
    padding: 3px 6px;
  }

  &.button-more {
    padding-left: 3px;
    padding-right: 3px;
  }

  & + .separator {
    margin: 0 2px;
    width: 1px; height: 21px;
    background-color: rgba(#fff, .1);
  }
}

.button-group {
  border-radius: calc(2px + var(--missive-border-radius));
  background: var(--button-color);
  padding: 2px;
  box-shadow: 0 0 0 1px rgba(var(--missive-text-color-a-rgb), .05);

  .button + .button { margin-left: 1px }
  .button {
    background: transparent;

    &:hover {
      background: rgba(0,0,0, 0.1);
    }
  }
}

.button-async {
  position: relative;

  .loading-icon {
    display: none;
    pointer-events: none;
    position: absolute;
    top: 50%; left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;

  }

  .icon { color: currentColor }
  .icon, svg { animation-play-state: paused !important }

  &--loading {
    .button-async-label { opacity: 0 }
    .loading-icon { display: block }
    .icon, svg { animation-play-state: running !important }
  }
}

.button,
.button-token {
  position: relative;

  .button-icon {
    position: absolute;
    top: 50%; left: 50%;
    width: 0; height: 0;

    .icon {
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.button-link {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  color: var(--missive-text-color-c);
}

.button-token {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: .2em .8em .3em;
  border-radius: 10em;
  background-color: var(--missive-token-background-color);

  & + .button-token { margin-left: .4em }

  &--selected {
    color: var(--missive-conversation-list-background-color);
    background-color: var(--missive-text-color-a);
  }

  .button-group & {
    border-radius: 0;

    &:first-child { border-top-left-radius: 10em; border-bottom-left-radius: 10em }
    &:last-child { border-top-right-radius: 10em; border-bottom-right-radius: 10em }

    & + .button-token {
      margin-left: 0;
      border-left: 1px solid rgba(#000, .1);
      box-shadow: inset 1px 0 0 rgba(#fff, .4);
    }
  }
}

.button-secondary {
  color: var(--missive-text-color-b);

  font-weight: 500;
  width: auto !important;
  height: auto !important;
  padding: 6px;
  line-height: 17px;
  border-radius: var(--missive-border-radius);
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgba(0,0,0, .05);
  }
}

// --------
// - List -
// --------
.list-title {
  font-weight: 500;
  margin: .4em 0;
  padding: 0 12px;
}

.list-subtitle {
  font-weight: 500;
  padding: 0 12px;

  @extend
    .text-c,
    .text-small;
}

.list-title-icon {
  display: inline-block;
  width: 10px; height: 10px;
  margin-right: 8px;
  border-radius: 2.5px;
}

.list-items {
  overflow: hidden;
  .stack-item { border-color: var(--missive-conversation-list-background-color) }
}

.list-item {
  cursor: pointer;
  position: relative;

  & + .list-item {
    border-top: 1px solid var(--missive-background-color);
  }

  &:hover {
    background-color: var(--missive-light-active-color);
    .stack-item { border-color: var(--missive-light-active-color) }
  }
}

.list-item-tag-icon {
  display: inline-block;
  border-radius: 10px;
}

.list-item-meta + .list-item-meta {
  margin-left: 5px;
}

.list-item-due-at {
  font-size: 12px;
  color: var(--missive-text-color-c);

  &--overdue {
    color: var(--missive-red-color);
  }
}

// ---------
// - Stack -
// ---------
.stack-item {
  position: relative;
  width: 10px; height: 10px;
  border: 2px solid var(--missive-background-color);
  border-radius: 100%;
  box-sizing: content-box;

  & + .stack-item {
    margin-left: -7px
  }

  &:hover { z-index: 99 !important }

  .avatar { background-color: var(--missive-background-color) }
}

// -----------
// - Avatars -
// -----------
.avatar {
  border-radius: 100%;
  overflow: hidden;

  .avatar-initials {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.avatar-multiple {
  font-size: 12px;
  color: var(--missive-text-color-c);

  &.list-item-meta {
    margin-left: 2px;
  }
}

// --------
// - Tabs -
// --------
.tabs {
  user-select: none;
  margin: 0 10px -11px;
  padding: 2px 2px 11px;

  .tab {
    cursor: pointer;
    position: relative;
    padding: 4px 10px;
    border-radius: 5px 5px 0 0;
    color: var(--missive-text-color-c);
    font-size: calc(var(--missive-font-size) - 1px);
    font-weight: 500;

    &:hover {
      background-color: rgba(0,0,0, .05);
    }

    &--selected {
      color: var(--missive-text-color-b);
      background-color: rgba(0,0,0, .05);
    }

    & + .tab { margin-left: 2px }
  }
}

.tabs-action {
  margin-left: auto;
  margin-right: 5px;

  .button {
    color: var(--missive-text-color-c);
    padding: 4px;
    background-color: transparent;

    &:hover {
      background-color: rgba(0,0,0, .05);
    }
  }
}

.tabs-content {
  position: relative;
}

// ---------
// - Boxes -
// ---------
.light-box {
  background-color: var(--missive-conversation-list-background-color);
  border-radius: var(--missive-border-radius);
  box-shadow: 0 0 var(--missive-section-shadow-width) 1px var(--missive-section-shadow-color);
}

.box {
  border: 1px solid var(--missive-light-border-color);
  border-bottom-color: var(--missive-border-color);
  border-radius: var(--missive-border-radius);
  overflow: hidden;

  & + .box { margin-top: 1em }
}

.box-header, .box-content {
  padding: 1em;
}

.box-header + .box-content {
  border-top: 1px solid var(--missive-light-border-color);
}

.box-collapsable {
  position: relative;

  > .box-header {
    cursor: pointer;
    user-select: none;

    * { pointer-events: none }
  }

  > .box-content {
    display: none;
  }

  &--opened {
    > .box-header .icon-menu-right { transform: rotate(90deg) }
    > .box-content { display: block }
  }
}

// ----------
// - Alerts -
// ----------
.alert {
  z-index: 999;
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: var(--shadow-color);
}

.alert-box {
  max-width: 85%;
  border-radius: 6px;
  background-color: var(--missive-conversation-list-background-color);
  box-shadow: 0 15px 50px var(--shadow-color);
  overflow: hidden;
}

.alert-buttons {
  border-top: 1px solid var(--missive-light-border-color);
}

.alert-button {
  cursor: pointer;
}

.alert-button--active {
  color: #fff;
  background-color: var(--missive-blue-color);
  box-shadow: 0 -1px 0 var(--missive-blue-color);
}

// ----------------
// - Progress bar -
// ----------------
.progress-bar {
  position: relative;
  width: 100%; height: 8px;
  border-radius: 8px;
  background-color: var(--missive-conversation-list-background-color);
  box-shadow: 0 0 2px var(--missive-section-shadow-color);

  .progress-bar-outer {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 1px;
  }

  .progress-bar-inner {
    width: 0%; height: 100%;
    max-width: 100%; min-width: 6px;
    border-radius: 6px;
    background-color: var(--accent-color);
    transition: width var(--animation-duration) var(--animation-easing);
  }
}

// --------------
// - Animations -
// --------------
@keyframes rotate {
  0%   { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

@keyframes dasharray {
  50%  { stroke-dasharray: 58 }
}
