:root {
  --animation-duration: 225ms;
  --animation-easing: cubic-bezier(.4, 0, .2, 1);
  --checkmark-color: var(--missive-green-color);
  --accent-color: var(--missive-blue-color);
  --button-color: var(--missive-blue-color);
  --button-hover-color: var(--button-color);
  --shadow-color: rgba(0, 0, 0, .2);
}

html[data-theme="dark"] {
  --shadow-color: rgba(0, 0, 0, .7);
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

body, legend, input, textarea, button {
  color: var(--missive-text-color-a);
  font-size: var(--missive-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Apple Color Emoji, sans-serif;
  line-height: 1.4;
}

a {
  color: var(--accent-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

svg {
  fill: currentColor;
}

p {
  margin: .5em 0;
}

#component {
  width: 1px;
  min-width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.columns, .columns-vertical, .columns-middle, .columns-left, .columns-center, .columns-right, .columns-justify {
  display: flex !important;
}

.columns > *, .columns-vertical > *, .columns-middle > *, .columns-left > *, .columns-center > *, .columns-right > *, .columns-justify > * {
  flex-shrink: 0;
}

.columns-vertical {
  flex-direction: column;
}

.columns-middle {
  align-items: center;
}

.columns-left {
  justify-content: flex-start;
}

.columns-center {
  justify-content: center;
}

.columns-right {
  justify-content: flex-end;
}

.columns-justify {
  justify-content: space-between;
}

.columns-wrap {
  flex-wrap: wrap;
}

.column-grow {
  flex-grow: 1;
}

.column-shrink {
  min-width: 0;
  flex-shrink: 1;
}

.margin {
  margin: 1em;
}

.margin-top {
  margin-top: 1em;
}

.margin-right {
  margin-right: 1em;
}

.margin-bottom {
  margin-bottom: 1em;
}

.margin-left {
  margin-left: 1em;
}

.margin-xsmall {
  margin: .25em;
}

.margin-top-xsmall {
  margin-top: .25em;
}

.margin-right-xsmall {
  margin-right: .25em;
}

.margin-bottom-xsmall {
  margin-bottom: .25em;
}

.margin-left-xsmall {
  margin-left: .25em;
}

.margin-small {
  margin: .5em;
}

.margin-top-small {
  margin-top: .5em;
}

.margin-right-small {
  margin-right: .5em;
}

.margin-bottom-small {
  margin-bottom: .5em;
}

.margin-left-small {
  margin-left: .5em;
}

.margin-medium {
  margin: .75em;
}

.margin-top-medium {
  margin-top: .75em;
}

.margin-right-medium {
  margin-right: .75em;
}

.margin-bottom-medium {
  margin-bottom: .75em;
}

.margin-left-medium {
  margin-left: .75em;
}

.margin-large {
  margin: 1.2em;
}

.margin-top-large {
  margin-top: 1.2em;
}

.margin-right-large {
  margin-right: 1.2em;
}

.margin-bottom-large {
  margin-bottom: 1.2em;
}

.margin-left-large {
  margin-left: 1.2em;
}

.margin-xlarge {
  margin: 1.4em;
}

.margin-top-xlarge {
  margin-top: 1.4em;
}

.margin-right-xlarge {
  margin-right: 1.4em;
}

.margin-bottom-xlarge {
  margin-bottom: 1.4em;
}

.margin-left-xlarge {
  margin-left: 1.4em;
}

.padding {
  padding: 1em;
}

.padding-top {
  padding-top: 1em;
}

.padding-right {
  padding-right: 1em;
}

.padding-bottom {
  padding-bottom: 1em;
}

.padding-left {
  padding-left: 1em;
}

.padding-xsmall {
  padding: .25em;
}

.padding-top-xsmall {
  padding-top: .25em;
}

.padding-right-xsmall {
  padding-right: .25em;
}

.padding-bottom-xsmall {
  padding-bottom: .25em;
}

.padding-left-xsmall {
  padding-left: .25em;
}

.padding-small {
  padding: .5em;
}

.padding-top-small {
  padding-top: .5em;
}

.padding-right-small {
  padding-right: .5em;
}

.padding-bottom-small {
  padding-bottom: .5em;
}

.padding-left-small {
  padding-left: .5em;
}

.padding-medium {
  padding: .75em;
}

.padding-top-medium {
  padding-top: .75em;
}

.padding-right-medium {
  padding-right: .75em;
}

.padding-bottom-medium {
  padding-bottom: .75em;
}

.padding-left-medium {
  padding-left: .75em;
}

.padding-large {
  padding: 1.2em;
}

.padding-top-large {
  padding-top: 1.2em;
}

.padding-right-large {
  padding-right: 1.2em;
}

.padding-bottom-large {
  padding-bottom: 1.2em;
}

.padding-left-large {
  padding-left: 1.2em;
}

.padding-xlarge {
  padding: 1.4em;
}

.padding-top-xlarge {
  padding-top: 1.4em;
}

.padding-right-xlarge {
  padding-right: 1.4em;
}

.padding-bottom-xlarge {
  padding-bottom: 1.4em;
}

.padding-left-xlarge {
  padding-left: 1.4em;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.text-a {
  color: var(--missive-text-color-a);
}

.link-a {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--missive-text-color-a);
}

.text-b {
  color: var(--missive-text-color-b);
}

.link-b {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--missive-text-color-b);
}

.text-c, .list-subtitle, .text-label {
  color: var(--missive-text-color-c);
}

.link-c {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--missive-text-color-c);
}

.text-d {
  color: var(--missive-text-color-d);
}

.link-d {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--missive-text-color-d);
}

.text-e {
  color: var(--missive-text-color-e);
}

.link-e {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--missive-text-color-e);
}

.text-xsmall {
  font-size: calc(var(--missive-font-size)  + -4px);
}

.text-small, .list-subtitle, .text-label {
  font-size: calc(var(--missive-font-size)  + -2px);
}

.text-normal {
  font-size: calc(var(--missive-font-size)  + 0px);
}

.text-large {
  font-size: calc(var(--missive-font-size)  + 3px);
}

.text-xlarge {
  font-size: calc(var(--missive-font-size)  + 6px);
}

.text-400 {
  font-weight: 400;
}

.text-500 {
  font-weight: 500;
}

.text-600 {
  font-weight: 600;
}

.text-700 {
  font-weight: 700;
}

.text-800 {
  font-weight: 800;
}

.heading-c {
  color: var(--missive-text-color-c);
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.text-label {
  font-variant: all-petite-caps;
}

.ellipsis {
  white-space: nowrap;
  max-width: 100%;
  width: auto;
  text-overflow: ellipsis;
  flex-shrink: 1;
  overflow: hidden;
}

.section + .section {
  border-top: 1px solid var(--missive-light-border-color);
  margin-top: 1em;
  padding-top: 1em;
}

.row + .row, .row-small + .row {
  margin-top: .75em;
}

.row + .row-small, .row-small + .row-small {
  margin-top: .25em;
}

.code, .hljs {
  color: var(--accent-color);
  white-space: pre-wrap;
  background-color: var(--missive-background-color);
  border-radius: 3px;
  padding: .2em .5em .1em;
  font-family: Menlo, monospace;
  font-size: 85%;
}

.code a, .hljs a {
  text-decoration: underline;
}

.code a:hover, .hljs a:hover {
  text-decoration: none;
}

.image-container {
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  font-style: normal;
  display: flex;
  position: relative;
}

.icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.icon[data-badge] {
  position: relative;
}

.icon[data-badge]:after {
  content: attr(data-badge);
  color: var(--missive-text-color-c);
  margin-left: 3px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.loading-icon svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5px;
  stroke-linecap: round;
  stroke-dasharray: 48;
  stroke-dashoffset: 64px;
}

.loading-icon .icon-circle {
  animation: rotate 1s linear infinite both;
}

.loading-icon .icon-circle svg {
  animation: dasharray 1s linear infinite both;
}

.checkmark, .checkmark-large {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid var(--missive-light-border-color);
  border-radius: 50%;
  outline: 0;
  margin: 0;
}

.checkmark:hover, .checkmark-large:hover {
  border-color: var(--checkmark-color);
}

.checkmark:hover:after, .checkmark-large:hover:after {
  content: "";
  border-color: var(--checkmark-color);
}

.checkmark:after, .checkmark-large:after {
  width: 6px;
  height: 10px;
  border: solid var(--missive-text-color-d);
  border-width: 0 1.5px 1.5px 0;
  position: absolute;
  transform: translateX(6px)translateY(3px)rotate(45deg);
}

.checkmark:checked, .checkmark.checkmark--checked, .checkmark-large:checked, .checkmark-large.checkmark--checked {
  border-color: var(--checkmark-color);
  background-color: var(--checkmark-color);
}

.checkmark:checked:after, .checkmark.checkmark--checked:after, .checkmark-large:checked:after, .checkmark-large.checkmark--checked:after {
  content: "";
  border-color: var(--missive-conversation-list-background-color);
}

.checkmark-large {
  border-width: 2px;
}

.checkmark-large:after {
  transform: translateX(5px)translateY(2px)rotate(45deg);
}

input[type="text"], textarea {
  width: 100%;
  border-radius: var(--missive-border-radius);
  color: var(--text-color-a);
  border: none;
  border: 1px solid var(--missive-light-border-color);
  background-color: var(--missive-light-active-color);
  outline: none;
  padding: 8px;
}

input[type="text"]:focus, textarea:focus {
  box-shadow: 0 0 0 1px var(--missive-blue-color);
}

.switch {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 42px;
  height: 21px;
  background-color: currentColor;
  border-radius: 10em;
  position: relative;
}

.switch:after {
  content: "";
  width: 19px;
  height: 19px;
  transition: var(--animation-duration) var(--animation-easing);
  background-color: var(--missive-conversation-list-background-color);
  border-radius: 16px;
  transition-property: width, left, transform;
  position: absolute;
  top: 1px;
  left: 1px;
}

.switch:active:after {
  width: 24px;
}

.switch:checked:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.button {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #fff;
  border-radius: var(--missive-border-radius);
  background: var(--button-color);
  border-style: none;
  padding: 8px 10px;
  font-weight: 500;
  display: inline-block;
}

.button:hover:not(:disabled) {
  background: var(--button-hover-color);
}

.button:disabled {
  cursor: default;
  opacity: .5;
}

.button .button-icon-plus {
  font-size: 0;
  line-height: 0;
}

.button .button-icon-plus i {
  font-size: 18px;
  font-style: normal;
}

.button .button-icon-plus + span {
  margin-left: .3em;
}

.button.button-small {
  font-size: calc(var(--missive-font-size)  - 2px);
  padding: 3px 6px;
}

.button.button-more {
  padding-left: 3px;
  padding-right: 3px;
}

.button + .separator {
  width: 1px;
  height: 21px;
  background-color: rgba(255, 255, 255, .1);
  margin: 0 2px;
}

.button-group {
  border-radius: calc(2px + var(--missive-border-radius));
  background: var(--button-color);
  box-shadow: 0 0 0 1px rgba(var(--missive-text-color-a-rgb), .05);
  padding: 2px;
}

.button-group .button + .button {
  margin-left: 1px;
}

.button-group .button {
  background: none;
}

.button-group .button:hover {
  background: rgba(0, 0, 0, .1);
}

.button-async {
  position: relative;
}

.button-async .loading-icon {
  pointer-events: none;
  transform-origin: center;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.button-async .icon {
  color: currentColor;
}

.button-async .icon, .button-async svg {
  animation-play-state: paused !important;
}

.button-async--loading .button-async-label {
  opacity: 0;
}

.button-async--loading .loading-icon {
  display: block;
}

.button-async--loading .icon, .button-async--loading svg {
  animation-play-state: running !important;
}

.button, .button-token {
  position: relative;
}

.button .button-icon, .button-token .button-icon {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.button .button-icon .icon, .button-token .button-icon .icon {
  transform: translateX(-50%)translateY(-50%);
}

.button-link {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--missive-text-color-c);
  font-size: 12px;
  display: inline-block;
}

.button-token {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: var(--missive-token-background-color);
  border-radius: 10em;
  padding: .2em .8em .3em;
  font-size: 14px;
  display: inline-block;
}

.button-token + .button-token {
  margin-left: .4em;
}

.button-token--selected {
  color: var(--missive-conversation-list-background-color);
  background-color: var(--missive-text-color-a);
}

.button-group .button-token {
  border-radius: 0;
}

.button-group .button-token:first-child {
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
}

.button-group .button-token:last-child {
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
}

.button-group .button-token + .button-token {
  border-left: 1px solid rgba(0, 0, 0, .1);
  margin-left: 0;
  box-shadow: inset 1px 0 rgba(255, 255, 255, .4);
}

.button-secondary {
  color: var(--missive-text-color-b);
  border-radius: var(--missive-border-radius);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 6px;
  font-weight: 500;
  line-height: 17px;
  width: auto !important;
  height: auto !important;
}

.button-secondary:hover {
  background-color: rgba(0, 0, 0, .05);
}

.list-title {
  margin: .4em 0;
  padding: 0 12px;
  font-weight: 500;
}

.list-subtitle {
  padding: 0 12px;
  font-weight: 500;
}

.list-title-icon {
  width: 10px;
  height: 10px;
  border-radius: 2.5px;
  margin-right: 8px;
  display: inline-block;
}

.list-items {
  overflow: hidden;
}

.list-items .stack-item {
  border-color: var(--missive-conversation-list-background-color);
}

.list-item {
  cursor: pointer;
  position: relative;
}

.list-item + .list-item {
  border-top: 1px solid var(--missive-background-color);
}

.list-item:hover {
  background-color: var(--missive-light-active-color);
}

.list-item:hover .stack-item {
  border-color: var(--missive-light-active-color);
}

.list-item-tag-icon {
  border-radius: 10px;
  display: inline-block;
}

.list-item-meta + .list-item-meta {
  margin-left: 5px;
}

.list-item-due-at {
  color: var(--missive-text-color-c);
  font-size: 12px;
}

.list-item-due-at--overdue {
  color: var(--missive-red-color);
}

.stack-item {
  width: 10px;
  height: 10px;
  border: 2px solid var(--missive-background-color);
  box-sizing: content-box;
  border-radius: 100%;
  position: relative;
}

.stack-item + .stack-item {
  margin-left: -7px;
}

.stack-item:hover {
  z-index: 99 !important;
}

.stack-item .avatar {
  background-color: var(--missive-background-color);
}

.avatar {
  border-radius: 100%;
  overflow: hidden;
}

.avatar .avatar-initials {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.avatar-multiple {
  color: var(--missive-text-color-c);
  font-size: 12px;
}

.avatar-multiple.list-item-meta {
  margin-left: 2px;
}

.tabs {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px -11px;
  padding: 2px 2px 11px;
}

.tabs .tab {
  cursor: pointer;
  color: var(--missive-text-color-c);
  font-size: calc(var(--missive-font-size)  - 1px);
  border-radius: 5px 5px 0 0;
  padding: 4px 10px;
  font-weight: 500;
  position: relative;
}

.tabs .tab:hover {
  background-color: rgba(0, 0, 0, .05);
}

.tabs .tab--selected {
  color: var(--missive-text-color-b);
  background-color: rgba(0, 0, 0, .05);
}

.tabs .tab + .tab {
  margin-left: 2px;
}

.tabs-action {
  margin-left: auto;
  margin-right: 5px;
}

.tabs-action .button {
  color: var(--missive-text-color-c);
  background-color: rgba(0, 0, 0, 0);
  padding: 4px;
}

.tabs-action .button:hover {
  background-color: rgba(0, 0, 0, .05);
}

.tabs-content {
  position: relative;
}

.light-box {
  background-color: var(--missive-conversation-list-background-color);
  border-radius: var(--missive-border-radius);
  box-shadow: 0 0 var(--missive-section-shadow-width) 1px var(--missive-section-shadow-color);
}

.box {
  border: 1px solid var(--missive-light-border-color);
  border-bottom-color: var(--missive-border-color);
  border-radius: var(--missive-border-radius);
  overflow: hidden;
}

.box + .box {
  margin-top: 1em;
}

.box-header, .box-content {
  padding: 1em;
}

.box-header + .box-content {
  border-top: 1px solid var(--missive-light-border-color);
}

.box-collapsable {
  position: relative;
}

.box-collapsable > .box-header {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box-collapsable > .box-header * {
  pointer-events: none;
}

.box-collapsable > .box-content {
  display: none;
}

.box-collapsable--opened > .box-header .icon-menu-right {
  transform: rotate(90deg);
}

.box-collapsable--opened > .box-content {
  display: block;
}

.alert {
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: var(--shadow-color);
  position: fixed;
  top: 0;
  left: 0;
}

.alert-box {
  max-width: 85%;
  background-color: var(--missive-conversation-list-background-color);
  box-shadow: 0 15px 50px var(--shadow-color);
  border-radius: 6px;
  overflow: hidden;
}

.alert-buttons {
  border-top: 1px solid var(--missive-light-border-color);
}

.alert-button {
  cursor: pointer;
}

.alert-button--active {
  color: #fff;
  background-color: var(--missive-blue-color);
  box-shadow: 0 -1px 0 var(--missive-blue-color);
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: var(--missive-conversation-list-background-color);
  box-shadow: 0 0 2px var(--missive-section-shadow-color);
  border-radius: 8px;
  position: relative;
}

.progress-bar .progress-bar-outer {
  width: 100%;
  height: 100%;
  padding: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-bar .progress-bar-inner {
  width: 0%;
  height: 100%;
  max-width: 100%;
  min-width: 6px;
  background-color: var(--accent-color);
  transition: width var(--animation-duration) var(--animation-easing);
  border-radius: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes dasharray {
  50% {
    stroke-dasharray: 58;
  }
}

/*# sourceMappingURL=missive.css.map */
